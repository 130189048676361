<template>
  <b-container class="nopadding">
    <h3 v-if="pendingLessons">Pending</h3>
    <h3 v-else>My Lessons</h3>
    <b-table
      hover
      responsive
      show-empty
      emptyText="No lessons scheduled yet"
      :busy="isBusy"
      :items="lessons"
      :fields="fields"
      :tbody-tr-class="rowClass"
      selectable
      select-mode="single"
      @row-selected="onRowSelected"
      class="mt-3">
      <template v-slot:table-busy>
        <div class="text-danger my-2 text-center">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template v-slot:cell(cancel)="row">
        <b-button v-if="pendingLessons"
                  size="sm"
                  @click="cancelPopup(row.item, row.index)"
                  class="button small btn-danger">
          Reject
        </b-button>
        <b-button v-else
                  size="sm"
                  @click="cancelPopup(row.item, row.index)"
                  class="button small btn-danger">
          Cancel
        </b-button>
      </template>
      <template v-slot:cell(accept)="row">
        <b-button v-if="pendingLessons"
                  size="sm"
                  @click="acceptLesson(row.item, row.index)"
                  class="button small btn-success">
          Accept
        </b-button>
        <b-button v-else
                  size="sm"
                  @click="startLesson(row.item)"
                  class="button small btn-success"
                  :disabled="!row.item.can_start && row.item.state != 'Started'">
          {{row.item.state == 'Started' ? 'Go to Lesson' : 'Start'}}
        </b-button>
      </template>
    </b-table>
    <div v-show="error" class="alert alert-danger" role="alert">
      {{ error }}
    </div>
    <b-modal
      :id="pendingLessons ? 'modal-reject-lesson-pending' : 'modal-reject-lesson'"
      :title="pendingLessons ? 'Reject Lesson' : 'Cancel Lesson'"
      hide-footer
      size="sm"
      @shown="onModalShown(pendingLessons ? 'modal-reject-lesson-pending' : 'modal-reject-lesson')"
      @hidden="onModalHide"
      >
      <div class="content">
        Are you sure you want to {{pendingLessons ? 'reject' : 'cancel'}} this lesson?
      </div>
      <footer class="modal-footer">
        <button
          class="button small"
          @click="hideModal">No</button>
          <button
            class="button small btn-danger"
            @click="cancelLesson">{{pendingLessons ? 'Yes, Reject Lesson' :
                                                     'Yes, Cancel Lesson'}}</button>
      </footer>
    </b-modal>
  </b-container>
</template>

<script>
import * as momentTemp from 'moment';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import TeacherLessonsApi from '../../../api/TeacherLessons';
import EventBus from '../../common/utils/event-bus';

const moment = momentTemp.default;

export default {
  name: 'TeacherLessonsTable',
  props: {
    pendingLessons: Boolean,
  },
  data() {
    return {
      error: false,
      isBusy: true,
      fields: [
        {
          key: 'time_to',
          label: 'Time to lesson',
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) => {
            const startDate = moment(item.start_time);
            return this.$options.filters.capitalize(startDate.from());
          },
        },
        {
          key: 'start_time',
          label: 'Starting time',
          sortable: true,
          formatter: (value, key, item) => moment(item.start_time).format('DD/MM/YYYY HH:mm'),
        },
        {
          key: 'duration',
          label: 'Duration (min)',
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) => {
            const startDate = moment(item.start_time);
            const endDate = moment(item.end_time);
            const diff = endDate.subtract(startDate);
            return diff.minutes();
          },
        },
        {
          key: 'cancel',
          label: this.pendingLessons ? 'Reject' : 'Cancel',
          sortable: false,
        },
        {
          key: 'accept',
          sortable: false,
        },
      ],
      lessons: [],
      selectedLesson: null,
      selectedIndex: null,
    };
  },
  methods: {
    getAllLessons() {
      TeacherLessonsApi
        .all(this.pendingLessons)
        .then((response) => {
          this.isBusy = false;
          this.lessons = response.data;
        })
        .catch((error) => {
          this.isBusy = false;
          this.error = this.parseError(error, 'Unable to retrieve all classes');
        });
    },
    rowClass(item, type) {
      if (!item || type !== 'row') { return ''; }
      if (item.numAvailableLessons > 0) { return 'table-success'; }
      return '';
    },
    startLesson(lesson) {
      TeacherLessonsApi
        .start(lesson.id)
        .then((response) => {
          window.open(response.data.url, '_blank').focus();
        })
        .catch((error) => {
          this.error = this.parseError(error, 'Unable to start lesson');
        });
    },
    acceptLesson(lesson, index) {
      this.lessons.splice(index, 1); // Remove lesson from pending
      TeacherLessonsApi
        .accept(lesson.id)
        .then(() => {
          EventBus.$emit('acceptedPendingLesson', lesson);
        })
        .catch((error) => {
          this.error = this.parseError(error, 'Unable to accept lesson');
        });
    },
    cancelPopup(lesson, index) {
      this.showModal();
      this.selectedLesson = lesson;
      this.selectedIndex = index;
    },
    cancelLesson() {
      this.hideModal();
      this.lessons.splice(this.selectedIndex, 1); // Remove lesson from pending
      TeacherLessonsApi
        .cancel(this.selectedLesson.id)
        .catch((error) => {
          this.error = this.parseError(error, 'Unable to cancel lesson');
        });
      this.selectedLesson = null;
      this.selectedIndex = null;
    },
    hideModal() {
      this.$bvModal.hide(`modal-reject-lesson${this.pendingLessons ? '-pending' : ''}`);
    },
    showModal() {
      this.$bvModal.show(`modal-reject-lesson${this.pendingLessons ? '-pending' : ''}`);
    },
    onRowSelected(rows) {
      [this.selectedLesson] = rows;
      if (!this.selectedLesson) { return; }

      EventBus.$emit('showLesson', this.selectedLesson);
    },
    onModalShown(elemId) {
      console.log(elemId);
      const modalEl = document.querySelector(`#${elemId}`);
      disableBodyScroll(modalEl);
    },
    onModalHide() {
      clearAllBodyScrollLocks();
    },
  },
  created() {
    this.getAllLessons();
    if (!this.pendingLessons) {
      EventBus.$on('acceptedPendingLesson', (lesson) => {
        let i;
        for (i = 0; i < this.lessons.length; i += 1) {
          const newLesson = this.lessons[i];
          if (newLesson.start_time > lesson.start_time) {
            break;
          }
        }
        this.lessons.splice(i, 0, lesson);
      });
    }
  },
};
</script>

<style scoped>
  h3 {
    text-transform: none;
  }

  .nopadding {
    position: relative;
    padding: 0;
  }
</style>
