<template>
  <b-modal
    id="modal-no-credits"
    title="No credits"
    hide-footer
    @shown="onModalShown"
    @hidden="onModalHide">
    <div class="content">
      <p>Ooops it looks like you don't enough credits</p>
    </div>
    <footer class="modal-footer">
      <button class="button special small"
              @click="useFreeLesson"
              v-show="hasFreeLesson">Use Free Lesson</button>
      <button class="button special small"
              @click="addCredits">Add credits</button>
    </footer>
  </b-modal>
</template>
<script>
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import EventBus from '../../common/utils/event-bus';

export default {
  name: 'NotEnoughCredits',
  data() {
    return {
      hasFreeLesson: false,
    };
  },
  methods: {
    addCredits() {
      this.$router.push({
        name: 'Payments',
      });
    },
    useFreeLesson() {
      this.$bvModal.hide('modal-no-credits');
      EventBus.$emit('scheduleFreeLesson');
    },
    showModal(hasFreeLesson) {
      this.hasFreeLesson = hasFreeLesson;
      this.$bvModal.show('modal-no-credits');
    },
    onModalShown() {
      const modalEl = document.querySelector('#modal-no-credits');
      disableBodyScroll(modalEl);
    },
    onModalHide() {
      clearAllBodyScrollLocks();
    },
  },
  created() {
    EventBus.$on('showNotEnoughCredits', (hasFreeLesson = false) => {
      this.showModal(hasFreeLesson);
    });
  },
};
</script>
