<template>
  <b-modal
    id="modal-show-lesson"
    title="Lesson Details"
    hide-footer
    size="md"
    @shown="onModalShown"
    @hidden="onModalHide">
    <form>
        <slot name="teacher" :lesson="lesson"></slot>
        <div class="form-group row">
          <label for="start_time" class="col-sm-5 col-form-label">Start Time</label>
          <div class="col-sm-7">
            <p id="start_time">
              {{ lesson.start_time | moment('DD/MM/YYYY HH:mm') }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label for="end_time" class="col-sm-5 col-form-label">End Time</label>
          <div class="col-sm-7">
            <p id="end_time">
              {{ lesson.end_time | moment('DD/MM/YYYY HH:mm') }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label for="duration" class="col-sm-5 col-form-label">Duration</label>
          <div class="col-sm-7">
            <p id="duration">
              {{ duration }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label for="creation_date" class="col-sm-5 col-form-label">Created</label>
          <div class="col-sm-7">
            <p id="creation_date">
              {{ lesson.created | moment('DD/MM/YYYY') }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label for="state" class="col-sm-5 col-form-label">State</label>
          <div class="col-sm-7">
            <div :class="classLessonState" id="state">
              <p>{{ lesson.state }}</p>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="repeat" class="col-sm-5 col-form-label">Repeat</label>
          <div class="col-sm-7">
            <select id="repeat" v-model="lesson.repeat" disabled>
              <option value="never">Never</option>
              <option value="week">Every Week</option>
              <option value="month_week_number">Every Month</option>
              <option value="month_last">Every Month</option>
            </select>
            <div v-show="dateDescription"
              class="date-descriptions">
              <input type="radio" id="description" checked disabled>
              <label for="description">{{ dateDescription }}</label>
            </div>
          </div>
        </div>
        <slot name="students" :lesson="lesson"></slot>
        <div v-show="error" class="alert alert-danger">{{ error }}</div>
        <slot name="footer" :lesson="lesson"></slot>
      </form>
  </b-modal>
</template>
<script>
import Vue from 'vue';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import EventBus from '../utils/event-bus';
import MiscApi from '../../../api/Misc';

export default {
  name: 'ShowLesson',
  data() {
    return {
      error: null,
      dateDescription: null,
      lesson: {},
    };
  },
  methods: {
    getDateDescriptions() {
      if (!this.lesson.repeat || !this.lesson.repeat.startsWith('month')) { return; }

      MiscApi
        .describeMonthlyDate(this.lesson.start_time)
        .then((response) => {
          const allDateDescriptions = response.data;
          for (let i = 0; i < allDateDescriptions.length; i += 1) {
            const description = allDateDescriptions[i];
            if (description.id === this.lesson.repeat) {
              this.dateDescription = description.message;
              return;
            }
          }
        }).catch((error) => {
          this.error = this.parseError(error, 'Unable to describe datetime');
        });
    },
    onModalShown() {
      const modalEl = document.querySelector('#modal-show-lesson');
      disableBodyScroll(modalEl);
    },
    onModalHide() {
      clearAllBodyScrollLocks();
    },
  },
  computed: {
    duration() {
      const startDate = Vue.moment(this.lesson.start_time);
      const endDate = Vue.moment(this.lesson.end_time);
      const minutes = endDate.diff(startDate, 'minutes');
      return `${minutes} minutes`;
    },
    classLessonState() {
      if (!this.lesson || !this.lesson.state) { return ''; }

      const cssClass = this.lesson.state
        .toLowerCase()
        .replace(/ /g, '-');

      return `state ${cssClass}`;
    },
  },
  created() {
    EventBus.$on('showLesson', (lesson) => {
      this.lesson = lesson;
      this.dateDescription = null;
      this.getDateDescriptions();
      this.$bvModal.show('modal-show-lesson');
    });
  },
};
</script>

<style scoped>
  form {
    margin: 0;
  }

  .date-descriptions {
    padding-left:  25px;
  }

  .state {
    background-color: rgba(0,0,0,.075);
    font-style: italic;
    border-radius: 5px;
    text-align: center;
  }

  .state.pending-payment,
  .state.cancelled {
    background-color: #fae1e3;
  }

  .state.confirmed,
  .state.started {
    background-color: #d4edda;
  }

  .state.pending-reschedule {
    background-color: #feedb9;
  }

  .state.pending-approval-from-teacher {
    background-color: #d1ecf1;
  }
</style>
