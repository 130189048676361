<template>
  <show-lesson>
    <template v-slot:students="{ lesson }">
      <div class="form-group row">
          <label for="students" class="col-sm-5 col-form-label">Students</label>
          <div class="col-sm-7">
            <ul id="students">
              <li v-for="(name, index) in lesson.student_names" :key="index">
                {{ name }}
              </li>
            </ul>
          </div>
        </div>
    </template>
  </show-lesson>
</template>
<script>
import ShowLesson from '../../common/dashboard/ShowLesson.vue';

export default {
  name: 'ShowLessonTeacher',
  components: {
    ShowLesson,
  },
};
</script>

<style scoped>
li {
  padding: 0;
}

ul {
  list-style: none;
  padding: 0 1em;
  margin: 0;
}
</style>
