import Vue from 'vue';
import Api from './Api';
import methods from '../helpers/methods';

const END_POINT = 'student/lessons';

export default {
  all() {
    return Api.get(END_POINT);
  },
  join(lessonId) {
    return Api.get(`${END_POINT}/${lessonId}`);
  },
  takeCredit(lessonId) {
    return Api.post(`${END_POINT}/${lessonId}/take-credit`);
  },
  schedule(startTime, repeat, hasFreeLesson, spinner = true) {
    const data = {
      start_time: startTime,
      repeat,
    };
    return Api.post(
      `${END_POINT}${hasFreeLesson ? '/freelesson' : ''}`,
      data,
      { spinner },
    );
  },
  getAvailableTimes(day) {
    const timezone = methods.vueMethods.getLocalTimezone();
    const date = Vue.moment(day).format('YYYY-MM-DD');
    return Api.get(`${END_POINT}/available-times`, { params: { timezone, day: date } });
  },
};
