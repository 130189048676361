<template>
  <b-container class="nopadding">
    <h3>Scheduled Lessons</h3>
    <router-link :to="{ name: 'Schedule lesson' }"
                 class="schedule-lesson button special noselect"
                 v-b-modal.modal-schedule-lesson>Schedule Lesson</router-link>
    <router-link :to="{ name: 'Schedule lesson' }"
                 class="schedule-lesson button special noselect mobile"
                 v-b-modal.modal-schedule-lesson>
      <i class="fas fa-plus"></i>
    </router-link>
    <schedule-lesson @new-lesson="appendNewLesson"
                     :show="showScheduleModal"></schedule-lesson>
    <not-enough-credits></not-enough-credits>
    <b-table
      hover
      responsive
      show-empty
      emptyText="No lessons scheduled yet"
      :busy="isBusy"
      :items="items"
      :fields="visibleFields"
      :tbody-tr-class="rowClass"
      selectable
      select-mode="single"
      @row-selected="onRowSelected"
      class="mt-3">
      <template v-slot:table-busy>
        <div class="text-danger my-2 text-center">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template v-slot:cell(actions)="row">
        <b-button
          size="sm"
          @click="joinLesson(row.item, row.index, $event.target)"
          class="button small"
          :disabled="!row.item.can_join">Join Lesson</b-button>
      </template>
    </b-table>
    <div v-show="error" class="alert alert-danger" role="alert">
      {{ error }}
    </div>
  </b-container>
</template>

<script>
import * as momentTemp from 'moment';
import ScheduleLesson from './ScheduleLesson.vue';
import NotEnoughCredits from './NotEnoughCredits.vue';
import EventBus from '../../common/utils/event-bus';
import StudentLessonsApi from '../../../api/StudentLessons';

const moment = momentTemp.default;

export default {
  name: 'MyLessonsTable',
  components: {
    ScheduleLesson,
    NotEnoughCredits,
  },
  props: {
    showScheduleModal: Boolean,
  },
  data() {
    return {
      error: false,
      isBusy: true,
      selectedLesson: {},
      fields: [
        {
          key: 'time_to',
          label: 'Time to lesson',
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) => {
            const startDate = moment(item.start_time).tz(this.getLocalTimezone());
            return this.$options.filters.capitalize(startDate.from());
          },
        },
        {
          key: 'start_time',
          label: 'Date',
          sortable: true,
          formatter: (value, key, item) => {
            const startDate = moment(item.start_time).tz(this.getLocalTimezone());
            return startDate.format('DD/MM/YYYY HH:mm');
          },
          hideOnMobile: true,
        },
        {
          key: 'duration',
          label: 'Duration (min)',
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) => {
            const startDate = moment(item.start_time);
            const endDate = moment(item.end_time);
            return endDate.diff(startDate, 'minutes');
          },
          hideOnMobile: true,
        },
        {
          key: 'state',
          sortable: true,
        },
        {
          key: 'actions',
          sortable: false,
        },
      ],
      items: [],
    };
  },
  methods: {
    getAllLessons() {
      StudentLessonsApi
        .all()
        .then((response) => {
          this.isBusy = false;
          this.items = response.data;
        })
        .catch((error) => {
          this.isBusy = false;
          this.error = this.parseError(error, 'Unable to retrieve all lessons');
        });
    },
    joinLesson(lesson) {
      StudentLessonsApi
        .join(lesson.id)
        .then((response) => {
          window.open(response.data.url, '_blank').focus();
        })
        .catch((error) => {
          this.error = this.parseError(error, 'Unable to join lesson');
        });
    },
    rowClass(item, type) {
      if (!item || type !== 'row') { return ''; }
      if (item.numAvailableLessons > 0) { return 'table-success'; }
      return '';
    },
    appendNewLesson() {
      this.getAllLessons();
    },
    onRowSelected(rows) {
      [this.selectedLesson] = rows;
      if (!this.selectedLesson) { return; }

      EventBus.$emit('showLesson', this.selectedLesson);
    },
  },
  created() {
    this.getAllLessons();
    EventBus.$on('updateAllLessons', this.getAllLessons);
  },
  computed: {
    visibleFields() {
      // Show all fields for wide screens
      if (window.innerWidth >= 768) { return this.fields; }
      return this.fields.filter((field) => !field.hideOnMobile);
    },
  },
};
</script>

<style scoped>
  h3 {
    text-transform: none;
  }

  .nopadding {
    position: relative;
    padding: 0;
  }

  .button.schedule-lesson {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1em;
    text-transform: none;
    height: 2.5em;
    line-height: 2.5em;
    padding: 0 0.8em;
  }

  .button.schedule-lesson.mobile {
    display: none;
  }

  @media screen and (max-width: 439px) {
    .button.schedule-lesson {
      display: none;
    }

    .button.schedule-lesson.mobile {
      display: block;
    }
  }
</style>
