import Api from './Api';

const END_POINT = 'teacher/lessons';

export default {
  all(isToRetrievePendingLessons) {
    const pending = isToRetrievePendingLessons ? '/pending' : '';
    return Api.get(`${END_POINT}${pending}`);
  },
  start(lessonId, spinner = true) {
    return Api.post(`${END_POINT}/start/${lessonId}`, {}, { spinner });
  },
  accept(lessonId, spinner = true) {
    return Api.post(`${END_POINT}/accept/${lessonId}`, {}, { spinner });
  },
  cancel(lessonId, spinner = true) {
    return Api.post(`${END_POINT}/cancel/${lessonId}`, {}, { spinner });
  },
};
