<template>
  <show-lesson ref="showLesson">
    <template v-slot:teacher="{ lesson }">
      <div class="form-group row">
          <label for="teacher" class="col-sm-5 col-form-label">Teacher</label>
          <div class="col-sm-7">
            <p id="teacher">{{ lesson.teacher_name }}</p>
          </div>
        </div>
    </template>
    <template v-slot:footer="{ lesson }">
      <footer class="modal-footer" v-show="lesson.state && lesson.state === 'Pending payment'">
          <button class="button special small"
                  v-on:click.stop.prevent="takeCredit">Pay pending payment</button>
        </footer>
    </template>
  </show-lesson>
</template>
<script>
import EventBus from '../../common/utils/event-bus';
import ShowLesson from '../../common/dashboard/ShowLesson.vue';
import StudentLessonsApi from '../../../api/StudentLessons';

export default {
  name: 'ShowLessonStudent',
  components: {
    ShowLesson,
  },
  data() {
    return {
      showLesson: {},
    };
  },
  methods: {
    takeCredit() {
      StudentLessonsApi
        .takeCredit(this.$refs.showLesson.lesson.id)
        .then((response) => {
          this.$refs.showLesson.lesson = response.data;
          EventBus.$emit('updateAllLessons');
        })
        .catch((error) => {
          if (error.response.status === 402) {
            EventBus.$emit('showNotEnoughCredits');
            return;
          }

          this.error = this.parseError(error, 'Unable to take credit for classroom with payment pending');
        });
    },
  },
};
</script>
