/**
 * Holds all the Miscellaneous API requests
 */
import Api from './Api';

export default {
  contact(email, subject, message, spinner = true) {
    const data = {
      subject,
      message,
      email,
    };
    return Api.post('contact', data, { spinner });
  },
  describeMonthlyDate(date, spinner = true) {
    const data = {
      params: {
        date,
      },
    };
    return Api.get('describe-monthly-date', data, { spinner });
  },
  getStripeKey(spinner = true) {
    return Api.get('thirdparty/stripe/key', {}, { spinner });
  },
};
