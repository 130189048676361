<template>
  <section class="hub page">
    <student-dashboard v-if="currentUser.type === 'student'"
      :showScheduleModal="showScheduleModal"></student-dashboard>
    <teacher-dashboard v-else-if="currentUser.type === 'teacher'"></teacher-dashboard>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import StudentDashboard from '../../components/student/dashboard/Dashboard.vue';
import TeacherDashboard from '../../components/teacher/dashboard/Dashboard.vue';
import types from '../../store/types';

export default {
  name: 'Dashboard',
  props: {
    showScheduleModal: Boolean,
  },
  components: {
    StudentDashboard,
    TeacherDashboard,
  },
  computed: {
    ...mapGetters({
      currentUser: types.getters.user.CURRENT_USER,
    }),
  },
};
</script>
