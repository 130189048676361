<template>
  <b-modal
    id="modal-schedule-lesson"
    title="Schedule Lesson"
    hide-footer
    v-model="showModal"
    size="md"
    @show="onModalShow"
    @hidden="onModalHide">
    <div class="content">
      <v-calendar
        :attributes='attrs'
        :min-date='new Date()'
        :disabled-dates='disabledDates'
        is-expanded
        @dayclick='selectDay'
        color='gray'/>
      <div class="available-times">
        <h3>Available Times</h3>
        <ul>
          <p v-if="availableTimes.length === 0"
            class="no-available-times">
            No available times in this day
          </p>
          <li v-for="(time, index) in availableTimes"
              :key="index"
              @click="selectTime(time)"
              :class="{selected: lesson.time === time}">
            {{ time }}
          </li>
        </ul>
      </div>
      <div class="repeat">
        <h3>Repeat</h3>
        <select name="repeat" v-model="lesson.repeat"
          @change="selectRepeat">
          <option value="never" selected>Never</option>
          <option value="week">Every Week</option>
          <option value="month">Every Month</option>
        </select>
        <div v-show="lesson.repeat === 'month'" class="date-descriptions">
          <div v-for="description in dateDescriptions" :key="description.id">
            <input type="radio" :id="description.id" name="description_id"
              v-model="lesson.description" :value="description.id"
              @change="updateRouteParams">
            <label :for="description.id">{{ description.message }}</label>
          </div>
        </div>
        <div v-show="error" class="alert alert-danger" role="alert">
          {{ error }}
        </div>
      </div>
    </div>
    <footer class="modal-footer">
      <button class="button special small"
              :disabled="!canSchedule"
              @click="schedule()">Schedule</button>
    </footer>
  </b-modal>
</template>

<script>
import Vue from 'vue';
import EventBus from '../../common/utils/event-bus';
import StudentLessonsApi from '../../../api/StudentLessons';
import MiscApi from '../../../api/Misc';

export default {
  name: 'ScheduleClass',
  props: {
    show: Boolean,
  },
  data() {
    return {
      lesson: {
        day: new Date(),
        time: null,
        repeat: 'never',
        description: undefined,
        isDisabled: false,
      },
      disabledDates: {
        weekdays: [],
      },
      showModal: this.show,
      error: null,
      busyTimes: {},
      availableTimes: [],
      dateDescriptions: [],
      defaultAvailableTimes: [],
    };
  },
  methods: {
    selectDay(day) {
      this.lesson.day = day.date;
      this.lesson.day.isDisabled = day.isDisabled;
      this.lesson.time = null;
      this.error = null;
      this.updateMonthlyDateDescriptions();
      this.updateAvailableTimes();
      this.updateRouteParams();
    },
    selectTime(time) {
      this.lesson.time = time;
      this.error = null;
      this.updateRouteParams();
    },
    selectRepeat() {
      if (this.lesson.repeat === 'month') {
        this.updateMonthlyDateDescriptions();
      } else {
        this.lesson.description = undefined;
        this.updateRouteParams();
      }
    },
    updateMonthlyDateDescriptions() {
      if (this.lesson.repeat !== 'month') { return; }
      if (!this.lesson.day) { return; }

      MiscApi
        .describeMonthlyDate(this.lesson.day, false)
        .then((response) => {
          this.dateDescriptions = response.data;
          if (this.lesson.description) {
            for (let i = 0; i < this.dateDescriptions.length; i += 1) {
              const description = this.dateDescriptions[i];
              if (description.id === this.lesson.description) {
                return;
              }
            }
          }
          this.lesson.description = this.dateDescriptions[0].id;
          this.updateRouteParams();
        }).catch((error) => {
          this.error = this.parseError(error, 'Unable to describe datetime');
        });
    },
    updateRouteParams() {
      const query = {};
      if (this.lesson.day) {
        query.day = Vue.moment(this.lesson.day).format('DD/MM/YYYY');
      }
      if (this.lesson.time) {
        query.time = this.lesson.time;
      }
      if (this.lesson.repeat) {
        query.repeat = this.lesson.repeat;
      }
      if (this.lesson.description) {
        query.description = this.lesson.description;
      }
      // Ignore same route requests
      if (JSON.stringify(query) === JSON.stringify(this.$route.query)) {
        return;
      }
      this.$router.replace({
        name: 'Schedule lesson',
        query,
      });
    },
    schedule(hasFreeLesson = false) {
      if (!this.lesson.time) {
        this.error = 'Please select a time for the lesson';
        return;
      }

      let scheduleTime = Vue.moment(this.lesson.day).format('DD/MM/YYYY');
      scheduleTime += ` ${this.lesson.time}`;
      scheduleTime = Vue.moment(scheduleTime, 'DD/MM/YYYY HH:mm');
      scheduleTime = scheduleTime.toISOString();
      const repeat = this.lesson.description ? this.lesson.description : this.lesson.repeat;
      StudentLessonsApi
        .schedule(scheduleTime, repeat, hasFreeLesson)
        .then(() => {
          this.$emit('new-lesson', { start_time: scheduleTime, repeat });
          this.$bvModal.hide('modal-schedule-lesson');
          window.fbq('track', hasFreeLesson ? 'StartTrial' : 'Schedule');
        })
        .catch((error) => {
          if (error.response.status === 402) {
            EventBus.$emit('showNotEnoughCredits', error.response.data.freelesson);
            return;
          }
          this.error = this.parseError(error, 'Unable to schedule a lesson, please try again');
          if (this.error !== 'Unable to schedule a lesson, please try again') {
            this.updateAvailableTimes();
          }
        });
    },
    updateAvailableTimes() {
      if (this.isCurrentDayDisabled()) {
        this.availableTimes = [];
        return;
      }

      StudentLessonsApi
        .getAvailableTimes(this.lesson.day)
        .then(({ data }) => {
          this.availableTimes = [];
          const interval = data.interval_between_lessons;
          for (let i = 0; i < data.available_times.length; i += 1) {
            const time = data.available_times[i];
            let start = this.prettyTime(time.start, true);
            const end = this.prettyTime(time.end, false);
            while (start <= end) {
              this.availableTimes.push(start.format('HH:mm'));
              start = start.add(interval, 'seconds');
            }
          }
        })
        .catch((error) => {
          this.error = this.parseError(error, 'Unable to retrieve day availability');
        });
    },
    isCurrentDayDisabled() {
      if (this.lesson.day.isDisabled) {
        return true;
      }
      if (this.lesson.day.isDisabled === undefined) {
        if ('weekdays' in this.disabledDates) {
          const weekday = Vue.moment(this.lesson.day).format('d');
          if (this.disabledDates.weekdays.includes(weekday)) {
            return true;
          }
        }
      }

      return false;
    },
    onModalShow() {
      if (this.$route.query) {
        if (this.$route.query.day) {
          this.lesson.day = Vue.moment(this.$route.query.day, 'DD/MM/YYYY').toDate();
        }
        if (this.$route.query.time) {
          this.lesson.time = this.$route.query.time;
        }
        if (this.$route.query.repeat) {
          this.lesson.repeat = this.$route.query.repeat;
        }
        if (this.$route.query.description) {
          this.lesson.description = this.$route.query.description;
        }
        this.selectRepeat();
      } else {
        this.lesson.day = new Date();
        this.lesson.time = null;
        this.lesson.repeat = 'never';
        this.lesson.description = undefined;
      }
      this.updateAvailableTimes();
    },
    onModalHide() {
      this.$router.push({
        name: 'Dashboard',
      });
    },
    prettyTime(t, isStart) {
      const time = Vue.moment(t);
      const minutes = time.minutes();
      if (minutes === 0 || minutes === 30) {
        return time;
      }

      const min = minutes > 30 ? 60 - minutes : 30 - minutes;
      if (isStart) {
        return time.add(min, 'minutes');
      }

      return time.subtract(min, 'minutes');
    },
  },
  computed: {
    attrs() {
      return [
        {
          key: 'selected',
          highlight: {
            contentClass: 'day-selected',
            class: 'day-selected',
          },
          dates: this.lesson.day,
        },
      ];
    },
    canSchedule() {
      return this.lesson && this.lesson.day && this.lesson.time && this.lesson.repeat;
    },
  },
  created() {
    EventBus.$on('scheduleFreeLesson', () => { this.schedule(true); });
  },
};
</script>

<style scoped>
  .available-times h3,
  .repeat h3 {
    text-transform: none;
    text-align: center;
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .repeat > select {
    border: solid 1px rgba(144, 144, 144, 0.25);
    margin-bottom: 15px;
  }

  .repeat > .date-descriptions {
    padding-left: 25px;
  }

  input[type="radio"]:checked + label:before {
    background: #f32853;
    border-color: #f32853;
  }

  .available-times ul {
    list-style: none;
    font-family: sans-serif;
    padding: 0;
    margin-left: -5px;
    margin-bottom: 15px;
  }

  .available-times ul li {
    display: inline-block;
    text-align: center;
    width: 100px;
    padding: 0;
    color: #f32853;
    border-color: #f32853;
    border-radius: 5px;
    border: 1px solid;
    margin-left: 5px;
    margin-top: 5px;
  }

  .available-times ul .no-available-times {
    text-align: center;
  }

  .available-times ul li:hover,
  .available-times ul li.selected {
    cursor: pointer;
    color: #fff;
    background-color: #f32853;
  }
</style>

<style>
  span.vc-day-content.day-selected {
    background-color: #fff !important;
    color: #f32853;
    border-color: #f32853;
    border-radius: 5px;
    border: 1px solid;
  }

  .vc-title,
  .vc-day-content {
    color: #484848;
  }
</style>
