<template>
  <section class="teacher-dashboard">
    <show-lesson></show-lesson>
    <my-lessons></my-lessons>
    <my-lessons :pendingLessons="true"></my-lessons>
  </section>
</template>

<script>
import MyLessons from './MyLessons.vue';
import ShowLesson from './ShowLesson.vue';

export default {
  name: 'TeacherDashboard',
  components: {
    ShowLesson,
    MyLessons,
  },
};
</script>
