<template>
  <section class="student-dashboard">
    <show-lesson></show-lesson>
    <my-lessons :showScheduleModal="showScheduleModal"></my-lessons>
  </section>
</template>

<script>
import MyLessons from './MyLessons.vue';
import ShowLesson from './ShowLesson.vue';

export default {
  name: 'StudentDashboard',
  props: {
    showScheduleModal: Boolean,
  },
  components: {
    MyLessons,
    ShowLesson,
  },
};
</script>
